import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './sidemenu.scss';

export default function Sidemenu({ open, close }) {
    const sendSlackNotify = (e) => {
        e.preventDefault();
        console.log(e);

        fetch('https://hooks.slack.com/services/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', {
            method: 'POST',
            body: JSON.stringify({
                text: `Nom : ${document.querySelectorAll('input[name=lastname]')[0].value},
                Prénom : ${document.querySelectorAll('input[name=firstname]')[0].value},
                 Téléphone : ${document.querySelectorAll('input[name=phone]')[0].value},
                 Email : ${document.querySelectorAll('input[name=email]')[0].value},
                 Project : ${document.querySelectorAll('textarea')[0].value}`,
            }),
        });
    };

    return (
        <div className={`sidemenu ${open ? 'open' : ''}`}>
            <div className="times-icon" onClick={close}>
                x
            </div>
            <h3>Mon projet</h3>
            <form onSubmit={sendSlackNotify}>
                <div>
                    <span>Nom</span> <input name="lastname" />
                </div>
                <div>
                    <span>Prénom</span> <input name="firstname" />
                </div>
                <div>
                    <span>Téléphone</span> <input name="phone" />
                </div>
                <div>
                    <span>Email</span> <input name="email" />
                </div>
                <div>
                    <span>Projet </span>
                    <textarea name="projectInfo" />
                </div>
                <GoogleReCaptchaProvider reCaptchaKey="6LewJsAZAAAAAIopyvExA1EJIkH905eog9UywQF2" />
                <button type="submit">Envoyer</button>
            </form>
        </div>
    );
}
